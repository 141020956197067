import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Row, Col } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from "sweetalert2";
import { AddedEquipmentContext } from './../../context/addedEquipmentContext';

const ProductModal = ({ isOpen, handleClose, product }) => {

    // States for checkbox options
    const [option1, setOption1] = useState('')
    const [option2, setOption2] = useState('')
    const [option3, setOption3] = useState('')
    const [option4, setOption4] = useState('')
    const [option5, setOption5] = useState('')
    const [province, setProvince] = useState('')

    // Use the useState hook to create state variables for each field
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const { addedEquipment, addEquipment } = useContext(AddedEquipmentContext)
    const [formData, setFormData] = useState({ name: '', phone: '', society: '', address: '', email: '', message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment, customertype: '', quoteType: '', places: '', solarKitType: '', budget: '' })
    // console.log('addedEquipment', addedEquipment)
    // This function will be called when the user submits the form
    const handleSubmit = (event) => {
        setErrors({})
        setLoading(true)
        if (validate() === true) {
            setLoading(true)
            // Prevent the default form submission behavior
            event.preventDefault();
            setFormData({ ...formData, product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment, customertype: option1, quoteType: option2, places: option3, solarKitType: option4, budget: option5 })

            console.log('formData', formData)
            // Do something with the form values, like send them to an API

            const URL = 'https://greenline.thewebsquare.com/api/'
            // const URL = 'http://127.0.0.1:8000/api/'
            axios.post(URL + 'submitSimulatorContactDetails', formData)
                .then((res) => {
                    console.log(res)
                    if (res && res?.data) {
                        handleClose()
                        setLoading(false)
                        setFormData({
                            name: '', phone: '', society: '', address: '', email: '',
                            message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment,
                            customertype: option1, quoteType: option2, places: option3, solarKitType: option4, budget: option5
                        })
                        Swal.fire('La demande a été soumise avec succès !', '', 'success')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                })
        }
    };

    const validate = () => {
        let tempErrors = { ...errors };
        let isValid = true;

        if (formData.name === undefined || formData.name === '' || formData.name === null) {
            tempErrors.name = 'Le nom est requis';
            isValid = false;
        } else {
            delete tempErrors.name;
        }
        if (formData.email === undefined || formData.email === '' || formData.email === null) {
            tempErrors.email = "L'e-mail est requis";
            isValid = false;
        } else if (!isValidEmail()) {
            tempErrors.email = "Email invalide";
            isValid = false;
        } else {
            delete tempErrors.email;
        }
        if (formData.phone === undefined || formData.phone === '' || formData.phone === null) {
            tempErrors.phone = 'Le téléphone est requis';
            isValid = false;
        } else {
            delete tempErrors.phone;
        }
        if (formData.society === undefined || formData.society === '' || formData.society === null) {
            tempErrors.society = 'Le société est requis';
            isValid = false;
        } else {
            delete tempErrors.society;
        }
        if (option1 == '') {
            tempErrors.option1 = 'Le option est requise';
            isValid = false;
        } else {
            delete tempErrors.option1;
        }
        if (option2 == '') {
            tempErrors.option2 = 'Le option est requise';
            isValid = false;
        } else {
            delete tempErrors.option2;
        }
        if (option3 == '') {
            tempErrors.option3 = 'Le option est requise';
            isValid = false;
        } else {
            delete tempErrors.option3;
        }
        if (option3 == 'Province' && province == '') {
            tempErrors.province = 'Le option est requise';
            isValid = false;
        } else {
            delete tempErrors.option3;
        }
        // if (formData.society === undefined || formData.society === '' || formData.society === null) {
        //     setErrors({ ...errors, society: 'La société est nécessaire' })
        //     setLoading(false)
        //     return false
        // }
        setErrors(tempErrors);
        setLoading(false);
        return isValid;
    }

    const isValidEmail = () => {
        return /\S+@\S+\.\S+/.test(formData.email);
    }

    useEffect(() => {
        setErrors({})
        setFormData({ name: '', phone: '', society: '', address: '', email: '', message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment, customertype: '', quoteType: '', places: '', solarKitType: '', budget: '' })
    }, [isOpen])



    return (
        <Form method="POST">
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="lg"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${product?.name} - ${product?.puissance}W`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} style={{ marginBottom: 10 }}>
                            <Form.Group>
                                <Form.Label>Nom *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData?.name}
                                    placeholder='Nom'
                                    onChange={(event) => setFormData({ ...formData, name: event.target.value })}
                                    required
                                />
                                <span className='text-danger'>{errors?.name}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                            <Form.Group>
                                <Form.Label>E-mail *</Form.Label>
                                <Form.Control
                                    placeholder='E-mail'
                                    type="email"
                                    value={formData?.email}
                                    onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.email}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} style={{ marginBottom: 10 }}>
                            <Form.Group>
                                <Form.Label>Téléphone *</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder='Téléphone'
                                    value={formData?.phone}
                                    onChange={(event) => setFormData({ ...formData, phone: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.phone}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                            <Form.Group>
                                <Form.Label>Société *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Société'
                                    value={formData?.society}
                                    onChange={(event) => setFormData({ ...formData, society: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.society}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control value={formData?.address} type="text" as="textarea" rows={3} placeholder="Adresse"
                            onChange={(event) => setFormData({ ...formData, address: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Message</Form.Label>
                        <Form.Control value={formData?.message} type="text" as="textarea" rows={3} placeholder="Message"
                            onChange={(event) => setFormData({ ...formData, message: event.target.value })}
                        />
                    </Form.Group>
                    {/* Option 1 */}
                    <div className="modal-body">
                        <div className="option">
                            <div className="optionHeading">
                                <h6>Je suis un* :</h6>
                            </div>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option1 == "Particulier"}
                                    onChange={() => {
                                        setOption1('Particulier')
                                        setFormData({ ...formData, customertype: 'Particulier' })
                                    }}
                                />
                                <span className="option-text">Particulier</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option1 == "Societe"}
                                    onChange={() => {
                                        setOption1('Societe')
                                        setFormData({ ...formData, customertype: 'Societe' })
                                    }}
                                />
                                <span className="option-text">Société</span>
                            </label>

                        </div>
                        <span className='text-danger'>{errors?.option1}</span>
                    </div>

                    {/* Option 2 */}
                    <div className="modal-body">
                        <div className="option">
                            <div className="optionHeading">
                                <h6>Je veux un devis* :</h6>
                            </div>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option2 == "Avec Installation"}
                                    onChange={() => {
                                        setOption2('Avec Installation')
                                        setFormData({ ...formData, quoteType: 'Avec Installation' })
                                    }}
                                />
                                <span className="option-text">Avec Installation</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option2 == "Sans Installation"}
                                    onChange={() => {
                                        setOption2('Sans Installation')
                                        setFormData({ ...formData, quoteType: 'Sans Installation' })
                                    }}
                                />
                                <span className="option-text">Sans Installation</span>
                            </label>

                        </div>
                        <span className='text-danger'>{errors?.option2}</span>
                    </div>

                    {/* Option 3 */}
                    <div className="modal-body">
                        <div className="option">
                            <div className="optionHeading">
                                <h6>Lieux* :</h6>
                            </div>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option3 == "Antananarivo"}
                                    onChange={() => {
                                        setOption3('Antananarivo')
                                        setFormData({ ...formData, places: 'Antananarivo' })
                                    }}
                                />
                                <span className="option-text">Antananarivo</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option3 == "Province"}
                                    onChange={() => {
                                        setOption3('Province')
                                        setFormData({ ...formData, places: 'Province' })
                                    }}
                                />
                                <span className="option-text">Province</span>
                            </label>
                        </div>
                        {option3 == "Province" && <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder='Province'
                                onChange={(event) => {
                                    setProvince(event.target.value)
                                    setFormData({ ...formData, places: event.target.value })
                                }}
                                required
                            />
                            <span className='text-danger'>{errors?.province}</span>
                        </Form.Group>}
                        <span className='text-danger'>{errors?.option3}</span>
                    </div>

                    {/* Option 4 */}
                    <div className="modal-body">
                        <div className="option">
                            <div className="optionHeading">
                                <h6>Je veux un kit solaire :</h6>
                            </div>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option4 == "Hybride avec la jirama"}
                                    onChange={() => {
                                        setOption4('Hybride avec la jirama')
                                        setFormData({ ...formData, solarKitType: 'Hybride avec la jirama' })
                                    }}
                                />
                                <span className="option-text">Hybride avec la jirama</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option4 == "100% Autonome"}
                                    onChange={() => {
                                        setOption4('100% Autonome')
                                        setFormData({ ...formData, solarKitType: '100% Autonome' })
                                    }}
                                />
                                <span className="option-text">100% Autonome</span>
                            </label>

                        </div>
                    </div>

                    {/* Option 5 */}
                    <div className="modal-body">
                        <div className="option">
                            <div className="optionHeading">
                                <h6>Mon Budget est de ( Ariary ):</h6>
                            </div>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "- de 5 millions"}
                                    onChange={() => {
                                        setOption5('- de 5 millions')
                                        setFormData({ ...formData, budget: '- de 5 millions' })
                                    }}
                                />
                                <span className="option-text">- de 5 millions</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "6 à 10 millions"}
                                    onChange={() => {
                                        setOption5('6 à 10 millions')
                                        setFormData({ ...formData, budget: '6 à 10 millions' })
                                    }}
                                />
                                <span className="option-text">6 à 10 millions</span>
                            </label>

                        </div>
                        <div className="option">
                            <div className="optionHeading">
                                <h6></h6>
                            </div>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "10 à 15 millions"}
                                    onChange={() => {
                                        setOption5('10 à 15 millions')
                                        setFormData({ ...formData, budget: '10 à 15 millions' })
                                    }}
                                />
                                <span className="option-text">10 à 15 millions</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "15 à 20 millions"}
                                    onChange={() => {
                                        setOption5('15 à 20 millions')
                                        setFormData({ ...formData, budget: '15 à 20 millions' })
                                    }}
                                />
                                <span className="option-text">15 à 20 millions</span>
                            </label>

                        </div>
                        <div className="option">
                            <div className="optionHeading">
                                <h6></h6>
                            </div>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "20 à 30 millions"}
                                    onChange={() => {
                                        setOption5('20 à 30 millions')
                                        setFormData({ ...formData, budget: '20 à 30 millions' })
                                    }}
                                />
                                <span className="option-text">20 à 30 millions</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={option5 == "+ de 30 millions"}
                                    onChange={() => {
                                        setOption5('+ de 30 millions')
                                        setFormData({ ...formData, budget: '+ de 30 millions' })
                                    }}
                                />
                                <span className="option-text">+ de 30 millions</span>
                            </label>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>Annuler</Button>
                    {!loading ? <Button type="submit" disabled={loading} onClick={(e) => handleSubmit(e)}>
                        Soumettre
                    </Button> : <Button disabled={loading}>
                        <Spinner animation="border" size="sm" variant="light" style={{ marginRight: 5 }} />Chargement...
                    </Button>}

                </Modal.Footer>
            </Modal>
        </Form>
    )
}

export default ProductModal